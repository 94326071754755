import "intersection-observer";
import swiper from "./swiper";
import lazyload from "./dal-lazyload";
// import contact from "./contact";
import AOS from "aos";
// import cookieBanner from "./cookie-banner";
// eslint-disable-next-line no-undef
require("./autoload"); //enforce to copy all images of src into web folder

(function () {

	swiper.init();

	lazyload.init();

	// contact.init();

	AOS.init();

	// remove comment if you want to add a cookie banner
	// cookieBanner.init();
})();


